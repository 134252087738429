import { XClose } from '@untitled-ui/icons-react';
import React, { useEffect, useRef } from 'react';

import useModalService from '@/services/modalService';

import Transition from './Transition';

const ModalView = () => {
  const { modals, close } = useModalService((state) => ({
    modals: state.modals,
    close: state.close,
  }));
  const modalContentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const clickHandler = ({ target }: MouseEvent) => {
      if (
        modalContentRef.current &&
        modalContentRef.current.contains(target as Node)
      ) {
        return;
      }

      const topModal = modals[modals.length - 1];
      if (topModal != null) {
        close(topModal.key);
      }
    };
    document.addEventListener('mousedown', clickHandler);

    const keyHandler = ({ keyCode }: KeyboardEvent) => {
      if (keyCode !== 27) return;
      const topModal = modals[modals.length - 1];
      if (topModal != null) {
        close(topModal.key);
      }
    };
    document.addEventListener('keydown', keyHandler);

    return () => {
      document.removeEventListener('mousedown', clickHandler);
      document.removeEventListener('keydown', keyHandler);
    };
  }, [modals, close]);

  return (
    <>
      <Transition
        className="fixed inset-0 z-50 h-full w-screen bg-slate-900/30 transition-opacity"
        show={modals.length > 0}
        enter="transition ease-out duration-200"
        enterStart="opacity-0"
        enterEnd="opacity-100"
        leave="transition ease-out duration-100"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
        appear={true}
      />
      {modals.map((modal, k) => (
        <Transition
          key={`modal.${modal.key}`}
          id={`modal.${modal.key}`}
          className={`fixed inset-0 !flex h-full w-screen items-start justify-center overflow-hidden ${
            k < modals.length - 1 ? 'z-40' : 'z-[60]'
          } ${modal.className}`}
          role="dialog"
          aria-modal="true"
          show={true}
          enter="transition ease-in-out duration-200"
          enterStart="opacity-0 translate-y-4"
          enterEnd="opacity-100 translate-y-0"
          leave="transition ease-in-out duration-200"
          leaveStart="opacity-100 translate-y-0"
          leaveEnd="opacity-0 translate-y-4"
          appear={true}
        >
          <div
            ref={k === modals.length - 1 ? modalContentRef : undefined}
            className={`box-border flex size-full max-w-7xl flex-col self-center overflow-visible rounded bg-white shadow-lg ${modal.containerClassName}`}
          >
            {modal.header != null && (
              <div className="flex h-max w-full shrink-0 flex-col gap-1 border-b p-6">
                <div className="flex justify-between gap-3">
                  <span className="text-lg font-semibold text-gray-900">
                    {modal.header.title}
                  </span>
                  <XClose
                    className="text-gray-500 hover:cursor-pointer"
                    onClick={() => close(modal.key)}
                  />
                </div>
                <span className="text-sm text-gray-600">
                  {modal.header.subtitle}
                </span>
              </div>
            )}
            <div className="grow overflow-auto p-6">{modal.content}</div>
          </div>
        </Transition>
      ))}
    </>
  );
};

export default ModalView;
