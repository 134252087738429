import type { HTMLProps, ReactNode } from 'react';
import { create } from 'zustand';

export interface ModalProps
  extends Pick<HTMLProps<HTMLDivElement>, 'className'> {
  key: string;
  header?: {
    title?: string;
    subtitle?: string;
    hideCloseButton?: boolean;
  };
  content: ReactNode | JSX.Element;
  containerClassName?: string;
}

export interface ModalState {
  modals: ModalProps[];
  open: (modal: ModalProps) => void;
  close: (key: string) => void;
}

const useModalService = create<ModalState>()((set, get) => ({
  modals: [],
  open: (modal) => {
    get().close(modal.key);
    set((state) => {
      const modals = [...state.modals];
      modals.push(modal);
      return { modals };
    });
  },
  close: (key) => {
    set((state) => {
      const modals = [...state.modals].filter((m) => m.key !== key);
      return { modals };
    });
  },
}));

export default useModalService;
