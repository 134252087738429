import Image from 'next/image';
import Link from 'next/link';
import React from 'react';

import { LogoImage } from '@/assets';

export default function FooterLogo() {
  return (
    <Link
      href="/#intro"
      scroll={false}
      className="flex flex-row items-center text-inherit"
      shallow
    >
      <Image
        src={LogoImage}
        alt="logo"
        className="h-10 w-auto rounded-lg object-cover py-2"
        unoptimized={true}
        crossOrigin="anonymous"
      />
    </Link>
  );
}
