import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import React from 'react';

import FooterLogo from './FooterLogo';

export default function Footer() {
  const { t } = useTranslation('common');
  return (
    <footer id="contact" className="bg-gray-800 text-white">
      <div>
        <div className="section gap-5 pb-14 pt-28">
          <div className="grid w-full gap-12 border-b border-gray-700 pb-4 sm:grid-cols-2 xl:gap-24">
            <div className="flex w-full flex-col gap-3">
              <FooterLogo />
              <span className="text-start text-gray-300">
                CÔNG TY TNHH GIẢI PHÁP PHẦN MỀM RIZERS - SOFT
              </span>
              <span className="text-start text-gray-300">
                <b>Mã số thuế: </b> 0317652639
              </span>
              <span className="text-start text-gray-300">
                <b>Địa chỉ: </b>72/19/8/29 Đường số 4, khu phố 6 , Phường Hiệp
                Bình Phước, Thành phố Thủ Đức, Thành phố Hồ Chí Minh, Việt Nam
              </span>
              <span className="text-start text-gray-300">
                <b>Hotline hỗ trợ khách hàng: </b>0374 828 046
              </span>
              <span className="text-start text-gray-300">
                <b>Email hỗ trợ kỹ thuật: </b>support@rizerssoft.com
              </span>
            </div>
            <div className="sm:justify-self-center">
              <div className="box-border flex w-fit flex-col gap-2">
                <span>
                  <Link href="/#intro">{t('footer.intro')}</Link>
                </span>
                <span>
                  <Link href="/#features">{t(`footer.features`)}</Link>
                </span>
                <span>
                  <Link href="/#pricing">{t(`footer.pricing`)}</Link>
                </span>
                <span>
                  <Link href="/#contact">{t(`footer.contact`)}</Link>
                </span>
                <span>
                  <Link href="/privacy-policy">
                    {t(`footer.privacy-policy`)}
                  </Link>
                </span>
                <span>
                  <Link href="/terms-and-conditions">
                    {t(`footer.term-and-conditions`)}
                  </Link>
                </span>
              </div>
            </div>
          </div>
          <div className="m-auto text-center">
            <div className="text-gray-300 sm:text-sm">
              ©2023 Hangrong.vn. All Rights Reserved
            </div>
            <div className="text-gray-300 sm:text-sm">
              <a
                href="https://www.rizerssoft.com/"
                aria-label="Powered by Rizerssoft Ltd"
                className="underline"
                target="_blank"
              >
                Powered by Rizerssoft Ltd
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
