import { useTranslation } from 'next-i18next';
import type { ButtonHTMLAttributes } from 'react';
import React, { forwardRef } from 'react';

export type IconButtonVariant =
  | 'primary'
  | 'secondary'
  | 'primaryTransparent'
  | 'secondaryTransparent'
  | 'redTransparent';

export interface IconButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  label?: string;
  icon?: JSX.Element | React.ReactNode;
  variant?: IconButtonVariant;
  disableTranslate?: boolean;
  keepTextHeight?: boolean;
}

const variants = {
  primary: {
    bg: 'bg-primary-400 shadow-primary-100 border-primary-400 hover:bg-primary-500 hover:border-primary-500 active:border-primary-600 active:shadow-[0_0_0_4px_#A7D8DE] disabled:bg-primary-200 disabled:border-primary-200',
    text: 'text-white',
  },
  secondary: {
    bg: 'bg-white border-primary-25 hover:bg-primary-50 hover:border-primary-50 active:bg-primary-100 active:border-primary-100',
    text: 'text-primary-500 hover:text-primary-500 active:text-primary-600 disabled:text-primary-100',
  },
  primaryTransparent: {
    bg: 'bg-transparent border-transparent !shadow-none p-1.5',
    text: 'text-primary-500',
  },
  secondaryTransparent: {
    bg: 'bg-transparent border-transparent !shadow-none p-1.5',
    text: 'text-white',
  },
  redTransparent: {
    bg: 'bg-transparent border-transparent !shadow-none p-1.5',
    text: 'text-red-600',
  },
};

const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  (
    {
      label,
      disableTranslate = false,
      keepTextHeight = true,
      icon,
      variant = 'primary',
      className,
      ...props
    },
    ref
  ) => {
    const { t } = useTranslation('common');
    return (
      <button
        ref={ref}
        className={`flex flex-row items-center justify-center rounded-lg border px-4 py-3 font-medium leading-tight shadow-sm transition duration-100 ease-in-out focus:outline-none focus:ring-0 ${variants[variant].bg} ${variants[variant].text} ${className}`}
        {...props}
      >
        <span className="pointer-events-none">{icon}</span>
        {!(!keepTextHeight && label == null) && (
          <span
            className={`pointer-events-none inline-block w-max font-semibold ${
              icon && label ? 'pl-2' : ''
            }`}
          >
            {!disableTranslate && label ? t(`buttons.${label}`) : label}
          </span>
        )}
      </button>
    );
  }
);

export default IconButton;
